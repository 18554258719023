import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import HeroSection from '../components/HeroSection'
import Mission from '../components/Mission'
import HomepageSection from '../components/HomepageSection'
import BlueSection from '../components/BlueSection'
import HistorySection from '../components/HistorySection'
import OpenPositions from '../components/OpenPositions'
import ContactSection from '../components/ContactSection'
import LocalizedLink from '../components/LocalizedLink'
import useTranslations from '../components/useTranslations'

import components from '../styles/components.module.sass'
import useMenu from '../components/useMenu'

const Index = ({
  data: {
    metadata,
    allMarkdownRemark,
    image01,
    image02,
    image03,
    image04,
    image05,
    image06,
  }
}) => {
  const [about] = useMenu()
  const blocks = allMarkdownRemark.edges.map(({ node }) => ({ ...node.frontmatter, html: node.html, excerpt: node.excerpt }))

  const blockDescription = blocks.find(({ block }) => block == 'description')
  const [block1, block2, block3, block4, block5, block6] = blocks

  const seo = metadata.nodes[0].childPagesJson.homepage

  return (
    <>
      <SEO title={seo.title} description={seo.description}  />
      <HeroSection />
      <Mission title={blockDescription.title} text={blockDescription.html} />
      <HomepageSection dark
        number={block1.number}
        title={block1.title}
        buttonLink={block1.link}
        fluid={image01.childImageSharp.fluid}
      >
        {block1.short || block1.excerpt}
      </HomepageSection>
      <HomepageSection reverse
        number={block2.number}
        title={block2.title}
        buttonLink={block2.link}
        fluid={image02.childImageSharp.fluid}
      >
        {block2.short || block2.excerpt}
      </HomepageSection>
      <HomepageSection
        number={block3.number}
        title={block3.title}
        buttonLink={block3.link}
        fluid={image03.childImageSharp.fluid}
      >
        {block3.short || block3.excerpt}
      </HomepageSection>
      <BlueSection blocks={[block4, block5, block6]} images={[image04, image05, image06]} />
      <HistorySection />
      <OpenPositions />
      <ContactSection />
    </>
  )
}

export default Index

export const query = graphql`
  query Index($locale: String!, ) {
    allMarkdownRemark(
      filter: {
        frontmatter: { locale: { eq: $locale } }
        fileAbsolutePath: {regex: "/(content)\/.*\\.md$/"}
      }
      sort: { fields: [frontmatter___number], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            block
            number
            title
            link
            image
            short
          }
          html
          excerpt
        }
      }
    }
    metadata: allFile(filter: {sourceInstanceName: {eq: "pages"}, name: {eq: $locale}}) {
      nodes {
        name
        childPagesJson {
          homepage {
            title
            description
          }
        }
      }
    }
    image01: file(sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "artworks"}, name: {eq: "01"}) {
      name
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 380, quality: 100) {
          src
          srcSet
          sizes
          presentationWidth
          aspectRatio
        }
      }
    }
    image02: file(sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "artworks"}, name: {eq: "02"}) {
      name
      childImageSharp {
        fluid(maxWidth: 560, maxHeight: 518, quality: 100) {
          src
          srcSet
          sizes
          presentationWidth
          aspectRatio
        }
      }
    }
    image03: file(sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "artworks"}, name: {eq: "03"}) {
      name
      childImageSharp {
        fluid(maxWidth: 560, maxHeight: 518, quality: 100) {
          src
          srcSet
          sizes
          presentationWidth
          aspectRatio
        }
      }
    }
    image04: file(sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "artworks"}, name: {eq: "04"}) {
      name
      childImageSharp {
        fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
          src
          srcSet
          sizes
          presentationWidth
          aspectRatio
        }
      }
    }
    image05: file(sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "artworks"}, name: {eq: "05"}) {
      name
      childImageSharp {
        fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
          src
          srcSet
          sizes
          presentationWidth
          aspectRatio
        }
      }
    }
    image06: file(sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "artworks"}, name: {eq: "06"}) {
      name
      childImageSharp {
        fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
          src
          srcSet
          sizes
          presentationWidth
          aspectRatio
        }
      }
    }
  }
`;
