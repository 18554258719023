import React from 'react'

import useTranslations from '../useTranslations'

import styles from './Mission.module.sass'

const Mission = ({ title, text }) => (
  <div className={styles.Wrapper}>
    <div className={styles.Container}>
      <h2>{title}</h2>
      <div className={styles.Text} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  </div>
)

export default Mission
