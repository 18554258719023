import React, { useCallback, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'

import { classes } from '../../utils/helpers'
import { ANIMATIONS } from '../../utils/constants'
import useTranslations, { useLocale } from '../useTranslations'
import { DesktopOnly } from '../atoms/MediaQueries'
import Logo from '../Logo'
import Arrow from '../../images/icons/arrow.svg'

import styles from './HistorySection.module.sass'
import components from '../../styles/components.module.sass'

const HistorySection = () => {
  const { rawData } = useStaticQuery(query)
  const locale = useLocale()
  const localizedData = rawData.edges.find(({ node}) => node.name === locale)
  const historyItems = localizedData.node.history.historyItems
  const [activeYear, setActiveYear] = useState('2006')
  const { briefHistory } = useTranslations()

  const [height, setHeight] = useState('auto')
  const updateHeight = useCallback((el) => {
    if (!el) return
    const { height } = el.getBoundingClientRect()
    setHeight(height)
  }, [setHeight])

  return (
    <div className={styles.Wrapper}>
      <section className={styles.Container}>
        <div className={styles.Slider}>
          <div className={styles.About}>
            <Logo />
            <p>{briefHistory}</p>
          </div>
          <hr />
          <motion.div
            className={styles.Slide}
            layout
            animate={{ height }}
          >
            <AnimatePresence exitBeforeEnter>
              <motion.div
                key={activeYear}
                variants={animations}
                {...ANIMATIONS}
                ref={updateHeight}
              >
                <DesktopOnly>
                  <motion.span variants={yearAnimations}>
                    {activeYear}
                  </motion.span>
                </DesktopOnly>
                <p>
                  {historyItems.find(({ year }) => year === activeYear).text}
                </p>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </div>
        <ul className={styles.Years}>
          {historyItems.map(({ year }) =>
            <li
              key={year}
              className={classes(styles.Year, year === activeYear && styles.YearActive)}
              onClick={() => setActiveYear(year)}
            >
              <div>{year}</div>
              <span />
            </li>
          )}
        </ul>
      </section>
    </div>
  )
}

export default HistorySection

const query = graphql`
  query history {
    rawData: allFile(filter: {
      sourceInstanceName: { eq: "history" }
    }) {
      edges {
        node {
          name
          history: childHistoryJson {
            historyItems {
              year
              text
            }
          }
        }
      }
    }
  }
`

const animations = {
  [ANIMATIONS.initial]: {
    opacity: 0,
    y: -30,
    transition: { type: 'tween', ease: 'easeIn'},
  },
  [ANIMATIONS.animate]: {
    opacity: 1,
    y: 0,
    transition: { type: 'tween', ease: 'easeOut'},
  },
  [ANIMATIONS.exit]: {
    opacity: 0,
    y: 30,
    transition: { type: 'tween', ease: 'easeIn'},
  },
}

const yearAnimations = {
  [ANIMATIONS.initial]: {
    opacity: 0,
  },
  [ANIMATIONS.animate]: {
    opacity: 1,
    transition: { delay: 0.2 },
  },
  [ANIMATIONS.exit]: {
    opacity: 1,
  },
}
