import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { useLocale } from '../useTranslations'

import styles from './HeroSection.module.sass'

const Title = ({ locale }) => {
  if (locale === 'cs') {
    return <h1>Strojírenský a&nbsp;automatizační <strong>Engineering</strong></h1>
  }
  if (locale === 'en') {
    return <h1>Engineering and&nbsp;automation <strong>Engineering</strong></h1>
  }
  return null
}

const HeroSection = () => {
  const locale = useLocale()
  const data = useStaticQuery(imageQuery)

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Video}>
        <video playsInline autoPlay muted loop id="bgvid">
          <source src="/assets/banner.mp4" type="video/mp4"/>
        </video>
      </div>
      <div className={styles.Container}>
        <Title locale={locale} />
        <Image
          className={styles.Img}
          fluid={data.file.childImageSharp.fluid}
          loading="eager"
          alt="Robot Artwork"
        />
      </div>
    </div>
  )
}

export default HeroSection

const imageQuery = graphql`
  query HeaderImage {
    file(sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "artworks"}, name: {eq: "homepage-header"}) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 100) {
          src
          srcSet
          sizes
          presentationWidth
          aspectRatio
        }
      }
    }
  }
`
