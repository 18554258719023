import React, { useRef } from 'react'
import Image from 'gatsby-image'
import { motion } from 'framer-motion'

import { classes } from '../../utils/helpers'
import useIntersection, { useReveal } from '../../hooks/useIntersection'
import useTranslations from '../useTranslations'
import LocalizedLink from '../LocalizedLink'

import styles from './HomepageSection.module.sass'
import components from '../../styles/components.module.sass'

const HomepageSection = ({
  children,
  number,
  title,
  img,
  fluid,
  buttonLink,
  buttonText = 'Detail',
  reverse = false,
  indented = false,
  dark = null
}) => {
  const [observerRef, inViewport] = useReveal()
  const { missionTitle, missionText } = useTranslations()
  const animation = {
    variants: contentAnimation,
    custom: reverse
  }
  return (
    <div className={classes(styles.Wrapper, dark && styles.dark)}>
      <div className={classes(styles.Container, reverse && styles.reverse, indented && styles.indented)} ref={observerRef}>
        <motion.div
          variants={contentAnimation}
          animate={inViewport ? 'show' : 'hide'}
          custom={!reverse}
        >
          {fluid
            ? <Image fluid={fluid} className={styles.Img} />
            : <img src={img} alt={title} />
          }
        </motion.div>
        <div>
          <motion.div
            className={classes(styles.Content, components.MarkdowContent)}
            variants={revealAnimation}
            initial="hide"
            animate={inViewport ? 'show' : 'hide'}
            custom={reverse}
          >
            <motion.span {...animation}>{number}</motion.span>
            <motion.h2 {...animation}>{title}</motion.h2>
            <motion.p {...animation}>{children}</motion.p>
            <motion.div {...animation}>
              <LocalizedLink className={components.ButtonLine} to={buttonLink}>
                {buttonText}
              </LocalizedLink>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default HomepageSection


const revealAnimation = {
  hide: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  },
}

const contentAnimation = {
  hide: reverse => ({
    opacity: 0,
    x: reverse ? -20 : 20,
  }),
  show: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.4
    }
  },
}
