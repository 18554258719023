import React from 'react'
import Image from 'gatsby-image'
import { motion } from 'framer-motion'

import { classes } from '../../utils/helpers'
import { useReveal } from '../../hooks/useIntersection'
import Arrow from '../../images/icons/arrow.svg'
import useTranslations from '../useTranslations'
import useMenu from '../../components/useMenu'
import LocalizedLink from '../LocalizedLink'

import styles from './BlueSection.module.sass'

const BlueSection = ({ blocks, images }) => {
  const [observerRef, inViewport] = useReveal()
  const { planning, electroInst, softwareSolutions } = useTranslations()
  const [about] = useMenu()

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container} ref={observerRef}>
        <ul>
          {blocks.map(({ number, title, link }, i) =>
            <motion.li
              key={title}
              variants={revealAnimation}
              initial="hide"
              animate={inViewport ? 'show' : 'hide'}
              custom={i}
            >
              {images[i].childImageSharp &&
                <Image
                  style={{ position: 'absolute' }}
                  className={styles.Img}
                  fluid={images[i].childImageSharp.fluid}
                />
              }
              <span>{number}</span>
              <h3>{title}</h3>
              <LocalizedLink to={link}>
                <Arrow />
                <span className={styles.LinkText}>{title}</span>
              </LocalizedLink>
            </motion.li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default BlueSection

const revealAnimation = {
  hide: {
    opacity: 0,
    x: -20
  },
  show: i => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.1 * i
    }
  }),
}
